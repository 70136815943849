<template>
  <v-container fluid class="pa-0">
    <v-form v-model="localValid">
      <v-row>
        <v-col>
          <v-text-field
            filled
            :rules="[presenceRule]"
            :label="$translations.t('models.Group.fields.name')"
            v-model="name"
            :loading="loading"
            dense
            @input="$emit('input', localValue)"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Vue from 'vue'

export default {
  name: "GroupForm",
  data: function() {
    return {
      name: this.value ? this.value.name : undefined,
      localValid: false,
    }
  },
  props: {
    value: {},
    valid: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
      default: false
    },
    bus: {
      type: Object,
      default: () => new Vue()
    }
  },
  mounted: function() {
    this.$emit('update:valid', this.localValid)
  },
  methods: {
    presenceRule(value) {
      return !!value || 'Il campo è obbligatorio'
    }
  },
  watch: {
    value(newValue) {
      if(!!newValue) {
        this.name = newValue.name
      }
    },
    localValid() {
      this.$emit('update:valid', this.localValid)
    }
  },
  computed: {
    localValue() {
      return {
        id: this.value ? this.value.id : undefined,
        uid: this.value ? this.value.uid : undefined,
        name: this.name,
      }
    }
  }
}
</script>

<style>

</style>