<template>
  <div>
    <TitleWithBackButton title="" back-route="Settings" icon="mdi-graphql"></TitleWithBackButton>
    <div class="pa-5">
      <div class="text-center py-2" v-if="loading">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <div class="text-center py-2">
          <v-btn icon @click="newGroup()" :disabled="loading">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-list color="transparent">
          <template v-for="group in groups">
            <v-list-group
              :key="group.id"
              @change="() => textFilter = undefined"
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-title>{{group.name}}</v-list-item-title>
                  <v-list-item-action>
                    <div class="d-flex" :style="{gap: '10px'}">
                      <v-btn icon @click.stop.prevent="editGroup(group)" :disabled="loading">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click.stop.prevent="deleteGroup(group)" :disabled="loading">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                      <v-tooltip allow-overflow bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            icon 
                            v-on="on"
                            v-bind="attrs"
                            @click.stop.prevent="editPermissions(group)" 
                            :disabled="loading"
                          >
                            <v-icon>mdi-lock-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Modifica permessi</span>
                      </v-tooltip>
                      <v-tooltip allow-overflow bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            icon 
                            @click.stop.prevent="newUser(group)" 
                            :disabled="loading"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon>mdi-account-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Aggiungi utente</span>
                      </v-tooltip>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </template>
              
              <div class="pl-8 mt-3 d-flex align-center" style="gap: 10px">
                <v-text-field
                  filled
                  dense
                  hide-details="auto"
                  placeholder="Cerca utenti ..."
                  v-model="textFilter"
                ></v-text-field>
                <v-switch
                  label="Visualizza disattivi"
                  v-model="deactiveFilter"
                ></v-switch>
              </div>

              <v-list 
                color="transparent" 
                :two-line="!currentUser || !currentUser.system"
                :three-line="!!currentUser && !!currentUser.system"
                class="pl-6"
              >
                <template v-for="user in group.users.filter((u) => {
                    let visible = true
                    if(!!textFilter) visible = visible && `${u.firstname} ${u.lastname}`.toLowerCase().includes(textFilter.toLowerCase())
                    if(!deactiveFilter) visible = visible && u.enabled
                    return visible
                  })">
                  <v-list-item :key="user.id" @click="editUser(user)">
                    <v-list-item-avatar>
                      <v-icon v-if="user.firstname.toLowerCase() == 'samuel'">mdi-crown</v-icon>
                      <v-icon v-else>mdi-account</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{user.firstname}} {{user.lastname}}</v-list-item-title>
                      <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
                      <v-list-item-subtitle class="text-caption font-weight-black primary--text" v-if="!!currentUser && !!currentUser.system">{{user.licenses.map((el) => el.name).join(', ')}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text class="d-flex align-center">
                        <div>
                          {{user.enabled ? 'Attivo' : 'Disattivo'}} 
                        </div>
                        <div class="ml-2">
                          <v-badge 
                            dot
                            :color="user.enabled ? 'success' : 'error'"
                          ></v-badge>
                        </div>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </v-list-group>
          </template>
        </v-list>
      </div>

      <StandardDialog v-model="editGroupDialog" :title="groupFormDialogTitle" :dialog-height="null" dialog-width="400px" persistent>
        <div>
          <GroupForm
            v-model="editingGroup" 
            class="py-2" 
            :loading="loading" 
            :bus="groupFormBus" 
            :valid.sync="groupValid"
            disable-email
          ></GroupForm>
        </div>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="editGroupDialog = false" :disabled="loading">Annulla</v-btn>
          <v-btn text color="default" @click="updateGroup" :loading="loading" :disabled="!groupValid">Salva</v-btn>
        </template>
      </StandardDialog>

      <StandardDialog v-model="newGroupDialog" :title="groupFormDialogTitle" :dialog-height="null" dialog-width="400px" persistent>
        <div>
          <GroupForm 
            v-model="editingGroup" 
            class="py-2" 
            :loading="loading" 
            :bus="groupFormBus" 
            :valid.sync="groupValid"
          ></GroupForm>
        </div>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="newGroupDialog = false" :disabled="loading">Annulla</v-btn>
          <v-btn text color="default" @click="createGroup" :loading="loading" :disabled="!groupValid">Salva</v-btn>
        </template>
      </StandardDialog>

      <FullScreenDialog
        v-model="editPermissionDialog"
        :title="`Permessi ${editingGroup.name}`"
      >
        <template v-slot:content>
          <PermissionsForm
            :groupId="editingGroup.id"
            :groupUid="editingGroup.uid"
          ></PermissionsForm>
        </template>

        <template v-slot:footer-actions>
          <div>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="error"
              @click="editPermissionDialog = false"
            >Chiudi</v-btn>
          </div>
        </template>
      </FullScreenDialog>

      <StandardDialog v-model="userEditFormDialog" :title="userFormDialogTitle" :dialog-height="null" persistent>
        <div>
          <UserForm 
            v-model="editingUser" 
            class="py-2" 
            :loading="loadingUserForm" 
            :bus="userFormBus" 
            :valid.sync="userValid"
            disable-email
          ></UserForm>
        </div>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="userEditFormDialog = false" :disabled="loadingUserForm">Annulla</v-btn>
          <v-btn text color="default" @click="updateUser" :loading="loadingUserForm" :disabled="!userValid">Salva</v-btn>
        </template>
      </StandardDialog>

      <StandardDialog v-model="userNewFormDialog" :title="userFormDialogTitle" :dialog-height="null" persistent>
        <div>
          <UserForm 
            v-model="editingUser" 
            class="py-2" 
            :loading="loadingUserForm" 
            :bus="userFormBus" 
            :valid.sync="userValid"
          ></UserForm>
        </div>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="userNewFormDialog = false" :disabled="loadingUserForm">Annulla</v-btn>
          <v-btn text color="default" @click="createUser" :loading="loadingUserForm" :disabled="!userValid">Salva</v-btn>
        </template>
      </StandardDialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import groupsService from "@/services/groups/groups.service.js"
import usersService from "@/services/users/users.service.js"
import StandardDialog from '@/components/common/StandardDialog.vue'
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import PermissionsForm from "@/components/setting/groups/PermissionsForm.vue"
import GroupForm from "@/components/setting/groups/GroupForm.vue"
import UserForm from "@/components/setting/users/UserForm.vue"
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

export default {
  name: "Groups",
  components: { 
    StandardDialog,
    GroupForm,
    FullScreenDialog,
    PermissionsForm,
    UserForm,
    TitleWithBackButton
  },
  data: function() {
    return {
      groups: [],
      loading: false,
      editingGroup: {},
      editGroupDialog: false,
      newGroupDialog: false,
      editPermissionDialog: false,
      groupValid: false,
      groupFormBus: new Vue(),
      userEditFormDialog: false,
      userNewFormDialog: false,
      editingUser: undefined,
      loadingUserForm: false,
      userFormBus: new Vue(),
      userValid: false,
      textFilter: undefined,
      deactiveFilter: false,
      currentUser: undefined
    }
  },
  mounted: function() {
    this.fetchGroups()
  },
  methods: {
    fetchGroups(loadingState = true) {
      if(loadingState) this.loading = true
      groupsService.list().then((groups) => {
        if(loadingState) this.loading = false
        this.groups = groups
      })
    },
    editGroup(group) {
      this.editingGroup = {...group}
      this.editGroupDialog = true
    },
    updateGroup() {
      this.loading = true
      groupsService.update(this.editingGroup).then((response) => {
        this.fetchGroups()
        this.editGroupDialog = false
        this.loading = false
      })
    },
    newGroup() {
      this.editingGroup = {}
      this.newGroupDialog = true
    },
    createGroup() {
      if(this.editingUser.groups.length == 0) return
      this.loading = true
      groupsService.create(this.editingGroup).then((response) => {
        this.fetchGroups()
        this.newGroupDialog = false
        this.loading = false
      })
    },
    deleteGroup(group) {
      let confirmed = confirm('Sei sicuro di voler eliminare il gruppo?')

      if(confirmed) {
        this.loading = true
        groupsService.delete(group).then((response) => {
          this.fetchGroups()
          this.loading = false
        })
      }
    },
    editPermissions(group) {
      this.editingGroup = {...group}
      this.editPermissionDialog = true
    },
    editUser(user) {
      this.editingUser = { ...user }
      this.userEditFormDialog = true
    },
    updateUser() {
      if(this.editingUser.groups.length == 0) return
      this.loading = true
      usersService.update(this.editingUser).then((result) => {
        this.userEditFormDialog = false
        this.fetchGroups(false)
        this.loading = false
      })
    },
    newUser(group) {
      this.editingUser = { 
        enabled: true,
        groups: [
          group
        ]
      }
      this.userNewFormDialog = true
    },
    createUser() {
      this.loading = true
      usersService.create(this.editingUser).then((result) => {
        this.userNewFormDialog = false
        this.fetchGroups(false)
        this.loading = false
      })
    }
  },
  computed: {
    groupFormDialogTitle() {
      return !!this.editingGroup && 
        (!!this.editingGroup.name) 
        ? (this.editingGroup.name || '')
        : "Form Gruppo"
    },
    userFormDialogTitle() {
      return !!this.editingUser && 
        (!!this.editingUser.firstname || !!this.editingUser.lastname) 
        ? (this.editingUser.firstname || '') + " " + (this.editingUser.lastname || '')
        : "Form Utente"
    }
  },
  currentUser: {
    bind: 'currentUser'
  }
}
</script>

<style>

</style>