<template>
  <div>
    <v-autocomplete
      v-model="selectedGroups"
      :items="groups"
      item-text="name"
      :item-value="itemValue"
      filled
      label="Gruppi"
      hide-details="auto"
      :placeholder="placeholder"
      :dense="dense"
      :multiple="multiple"
      :clearable="clearable"
      :loading="loading"
      :return-object="returnObject"
      :mandatory="mandatory"
      :error="error"
      @input="$emit('input', selectedGroups)"
    >
      <template slot="append-outer">
        <slot name="append-outer">
        </slot>
      </template>

      <template slot="prepend">
        <slot name="prepend">
          <v-icon>mdi-account-group</v-icon>
        </slot>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import groupsService from "@/services/groups/groups.service.js";

export default {
  name: "GroupsAutocomplete",
  data: function() {
    let selection;
    if(this.multiple && !this.returnObject) {
      selection = []
      for(let i = 0; i < this.value.length; i++) {
        selection.push(this.value[i].toString())
      }
    } else if(!this.returnObject) {
      selection = this.value.toString()
    } else {
      selection = this.value
    }

    return {
      groups: [],
      selectedGroups: selection,
      loading: false
    }
  },
  props: {
    value: {
      default: function() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.loadGroups()
  },
  methods: {
    loadGroups() {
      this.loading = true
      groupsService.options().then((groups) => {
        for(let i = 0; i < groups.length; i++) {
          groups[i][this.itemValue] = groups[i][this.itemValue].toString()
        }
        this.groups = groups
        this.loading = false
      })
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }
  },
  watch: {
    value(newVal) {
      if(this.multiple && !this.returnObject) {
        for(let i = 0; i < newVal.length; i++) {
          newVal[i] = newVal[i].toString()
        }
      } else if(!this.returnObject) {
        newVal = newVal.toString()
      }
      this.selectedGroups = newVal
    },
  }
}
</script>

<style>

</style>