import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';
import currentUserService from "@/services/currentUser.service";

class UsersService {
  list() {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/users/users/list', { page: 1, row_per_page: 150 }).then((response) => {
        if(response.success) {
          resolve(response.results.rows)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  notOperatorsAndEnabled() {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/users/users/list', { page: 1, row_per_page: 1000 }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  disable(user) {
    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  enable(user) {
    return new Promise((resolve, reject) => {
      resolve()
    })
  }

  update(user) {
    const apiUrl = UrlKeeper.getUrl('authenticator')
    delete user.id
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/users/users/update', user).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  async create(user) {
    const apiUrl = UrlKeeper.getUrl('authenticator')
    
    const currentUser = await currentUserService.getUser()
    const response = await axios.post(apiUrl + '/users/users/create', {
      ...user,
      licenses: currentUser.licenses
    })
    if (response.success) return response.results
    else throw response.results
  }

  /**
   * Send a reset password token by email
   * @param {Object} params - Send reset token params
   * @param {String} params.email - Email to send reset password
   */
  async sendResetToken(params) {
    if(!params || !params.email) throw new Error('email is not defined')
    apiUrl = UrlKeeper.getUrl('authenticator')

    const response = await axios.post(apiUrl + '/users/users/sendResetPasswordToken', {
      user: {
        email: params.email
      }
    })

    if(!response.success) {
      throw new Error('reset token not send')
    }
  }

  /**
   * 
   * @param {Object} params - reset password params
   * @param {String} params.uid - User uid
   * @param {String} params.token - Token to use
   * @param {String} params.password - new Password
   */
  async resetPassword(params) {
    if (!params || !params.email) throw new Error('email is not defined')
    apiUrl = UrlKeeper.getUrl('authenticator')

    const response = await axios.post(apiUrl + '/users/users/resetPassword', {
      user: {
        uid: params.uid
      },
      token: params.token,
      password: params.password
    })

    if (!response.success) {
      throw new Error('reset token not send')
    }
  }

  /**
   * Send a request for phone number confirmation
   * @param {Object} params - Send phone number confirmation params
   * @param {String} params.phoneNumber - Phone number to confirm
   */
  async requestConfirmNumberCode(params) {
    if (!params || !params.phoneNumber) throw new Error('phoneNumber is not defined')
    let apiUrl = UrlKeeper.getUrl('authenticator')

    const response = await axios.post(apiUrl + '/users/users/requestConfirmNumberCode', {
      phoneNumber: params.phoneNumber
    })

    if (!response.success) {
      throw response.results
    } else {
      return response.results
    }
  }

  /**
   * Check if the confirmation code is valid
   * @param {Object} params - Send phone number confirmation params
   * @param {String} params.phoneNumber - Phone number to confirm
   * @param {String} params.code - Code received by phone
   */
  async isConfirmNumberCodeValid(params) {
    if (!params || !params.phoneNumber) throw new Error('phoneNumber is not defined')
    else if (!params.code) throw new Error('code is not defined')
    let apiUrl = UrlKeeper.getUrl('authenticator')

    const response = await axios.post(apiUrl + '/users/users/isConfirmNumberCodeValid', {
      phoneNumber: params.phoneNumber,
      code: params.code
    })

    if (!response.success) {
      throw response.results
    } else {
      return response.results
    }
  }

  /**
   * Register a new license
   * @param {Object} params - Send phone number confirmation params
   * @param {String} params.phoneNumber - Phone number to confirm
   * @param {String} params.code - Code received by phone
   * @param {Object} params.licenseInfos - Info about the license
   * @param {String} params.licenseInfos.name - The name of the license
   * @param {String} params.licenseInfos.schemaName - The name of the schema (must be unique across all schema)
   * @param {String} params.licenseInfos.salonName - The name of the salon
   * @param {String} params.licenseInfos.salonAddress - The address of the salon
   * @param {String} params.licenseInfos.preset - The name of the preset to load
   * @param {Object} params.user - Info about the user
   * @param {String} params.user.email - user email
   * @param {String} params.user.firstname - user firstname
   * @param {String} params.user.lastname - user lastname
   * @param {String} params.user.phoneNumber - user phoneNumber
   */
  async register(params) {
    if (!params || !params.phoneNumber) throw new Error('phoneNumber is not defined')
    else if (!params.code) throw new Error('code is not defined')
    let apiUrl = UrlKeeper.getUrl('authenticator')

    const response = await axios.post(apiUrl + '/users/users/register', {
      phoneNumber: params.phoneNumber,
      code: params.code,
      licenseInfos: params.licenseInfos,
      user: params.user
    })

    if (!response.success) {
      throw response.results
    } else {
      return response.results
    }
  }
}

export default new UsersService()